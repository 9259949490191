import React, { useCallback, useEffect, useState } from 'react'
import './styles/networkError.css'

const ZK_CHAIN_ID = 380
const FIL_CHAIN_ID = 314159

const chainInfo: any = {
  [ZK_CHAIN_ID]: {
    chainName: 'ZK',
    rpc: 'https://serverpub.zkamoeba.com:4050/',
    symbol: 'FIL',
    symbolName: 'FIL',
  },
  [FIL_CHAIN_ID]: {
    chainName: 'FIL',
    rpc: 'https://filecoin-calibration.chainup.net/rpc/v1',
    symbol: 'FIL',
    symbolName: 'FIL',
  },
}

export const onAccountChange = (cb: Function) => {
  try {
    window?.ethereum?.on('accountsChanged', (accounts: string[]) => {
      if (accounts.length) cb(accounts[0])
      else cb(null)
    })
  } catch (err) {}
}

export const onChainChange = (cb: Function) => {
  try {
    window?.ethereum?.on('chainChanged', (chainID: string) => {
      if (chainID) cb(chainID)
      else cb(null)
    })
  } catch (err) {
    console.warn(err)
  }
}

const isMatchChainId = (): Promise<boolean> => {
  return new Promise(async (resolve, reject) => {
    setTimeout(() => {
      try {
        const chainID = window.ethereum.chainId
        if (typeof chainID === 'string') {
          const isMatch =
            Number(chainID) === Number(ZK_CHAIN_ID) ||
            Number(chainID) === Number(FIL_CHAIN_ID)
          resolve(isMatch)
        }

        if (typeof chainID === 'number') {
          const isMatch =
            Number(chainID.toString(10)) === ZK_CHAIN_ID ||
            Number(chainID.toString(10)) === FIL_CHAIN_ID
          resolve(isMatch)
        }
      } catch (error) {
        reject(error)
      }
    }, 100)
  })
}

const isMetaMaskInstalled = (): boolean => {
  const { ethereum } = window
  return Boolean(ethereum && ethereum.isMetaMask)
}

const switchNetwork = async (chainID: number) => {
  if (!isMetaMaskInstalled()) {
    window.open('https://metamask.io/download')
    return
  }
  const hexChainID = '0x' + Number(chainID).toString(16)
  try {
    await window?.ethereum?.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: hexChainID }],
    })
    window.location.reload()
  } catch (switchError: any) {
    if (switchError.code === 4902) {
      try {
        await window?.ethereum?.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: hexChainID,
              chainName: chainInfo[chainID].chainName,
              rpcUrls: [chainInfo[chainID].rpc],
              nativeCurrency: {
                name: chainInfo[chainID].symbolName,
                symbol: chainInfo[chainID].symbol,
                decimals: 18,
              },
            },
          ],
        })
        window.location.reload()
      } catch (addError) {
        // handle "add" error
      }
    }
  }
}

const NetworkError = (props: any) => {
  const [isMatchNetwork, setIsMatchNetwork] = useState<boolean>(true)

  const _isMatchChainId = useCallback(async () => {
    setIsMatchNetwork(await isMatchChainId())
  }, [])

  onAccountChange(() => {
    window.location.reload()
  })

  onChainChange(() => {
    window.location.reload()
  })

  useEffect(() => {
    if (props.open) {
      setIsMatchNetwork(false)
      return
    }
    _isMatchChainId()
  })

  return (
    <>
      {!isMatchNetwork && (
        <div className="network-error">
          <div className="content">
            <h1 className="content-title">Network Alert</h1>
            <p className="content-text">
              To use this system, please switch to the corresponding network
              <br />
              Kindly switch your network settings to proceed.
            </p>
            <div
              className="switch-to"
              onClick={switchNetwork.bind(this, FIL_CHAIN_ID)}
            >
              Switch to FIL
            </div>
            <div
              className="switch-to"
              onClick={switchNetwork.bind(this, ZK_CHAIN_ID)}
            >
              Switch to ZK
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default NetworkError
