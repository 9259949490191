import React, { useEffect, useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Button from '@mui/material/Button'
import clsx from 'clsx'

import {
  useChainbridge,
  useHomeBridge,
  useNetworkManager,
} from '@chainsafe/chainbridge-ui-core'
import { showImageUrl } from '../../utils/Helpers'
import { useStyles } from './styles'

import {
  TransferActiveModal,
  NetworkUnsupportedModal,
  PreflightModalTransfer,
  ChangeNetworkDrawer,
  AboutDrawer,
  NetworkSelectModal,
} from '../../modules'
import {
  AddressInput,
  TokenSelectInput,
  TokenInput,
  Fees,
  SelectDestinationNetwork,
} from '../../components'

import HomeNetworkConnectView from './HomeNetworkConnectView'
import { ethers, BigNumber } from 'ethers'
import makeValidationSchema from './makeValidationSchema'
import { BigNumber as BN } from 'bignumber.js'
import { NetworkError } from '../../components/NetworkError'
export type PreflightDetails = {
  tokenAmount: number
  token: string
  tokenSymbol: string
  receiver: string
}

const nativeTokens = window.__RUNTIME_CONFIG__.CHAINBRIDGE.chains.map((x) =>
  x.tokens.filter((item: any) => item.nativeToken)
)

const TransferPage = () => {
  const classes = useStyles()
  const { walletType, setWalletType } = useNetworkManager()

  const {
    deposit,
    setDestinationChain,
    transactionStatus,
    resetDeposit,
    bridgeFee,
    tokens,
    isReady,
    homeConfig,
    destinationChainConfig,
    destinationChains,
    address,
    checkSupplies,
  } = useChainbridge()

  const { nativeTokenBalance, accounts, selectAccount } = useHomeBridge()
  const [aboutOpen, setAboutOpen] = useState<boolean>(false)
  const [walletConnecting, setWalletConnecting] = useState(false)
  const [changeNetworkOpen, setChangeNetworkOpen] = useState<boolean>(false)
  const [preflightModalOpen, setPreflightModalOpen] = useState<boolean>(false)

  const [preflightDetails, setPreflightDetails] = useState<PreflightDetails>({
    receiver: '',
    token: '',
    tokenAmount: 0,
    tokenSymbol: '',
  })

  nativeTokens.map((_: any) => {
    const [tokenInfo] = _
    if (tokens[tokenInfo.address]) {
      tokens[tokenInfo.address].balance = nativeTokenBalance ?? 0
      tokens[tokenInfo.address].balanceBN =
        !nativeTokenBalance || isNaN(nativeTokenBalance)
          ? new BN(0)
          : new BN(nativeTokenBalance)
    }
  })

  useEffect(() => {
    if (walletType !== 'select' && walletConnecting === true) {
      setWalletConnecting(false)
    } else if (walletType === 'select') {
      setWalletConnecting(true)
    }
  }, [walletType, walletConnecting])

  const transferSchema = makeValidationSchema({
    preflightDetails,
    tokens,
    bridgeFee,
    homeConfig,
    destinationChainConfig,
    checkSupplies,
  })

  const { handleSubmit, control, setValue, watch, formState } =
    useForm<PreflightDetails>({
      resolver: yupResolver(transferSchema),
      defaultValues: {
        token: '',
        tokenAmount: 0,
        receiver: '',
      },
    })

  const watchToken = watch('token', '')
  const watchAmount = watch('tokenAmount', 0)

  const onSubmit: SubmitHandler<PreflightDetails> = (values) => {
    setPreflightDetails({
      ...values,
      tokenSymbol: tokens[values.token].symbol || '',
    })
    setPreflightModalOpen(true)
  }

  return (
    <div className={classes.root}>
      <HomeNetworkConnectView
        isReady={isReady}
        accounts={accounts}
        address={address}
        classes={classes}
        walletConnecting={walletConnecting}
        walletType={walletType}
        homeConfig={homeConfig}
        setWalletType={setWalletType}
        setChangeNetworkOpen={setChangeNetworkOpen}
        selectAccount={selectAccount}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <section>
          <SelectDestinationNetwork
            label="Destination Network"
            disabled={!homeConfig || formState.isSubmitting}
            options={destinationChains.map((dc: any) => ({
              label: dc.name,
              value: dc.domainId,
            }))}
            onChange={(value: number | undefined) => setDestinationChain(value)}
            value={destinationChainConfig?.domainId}
          />
        </section>
        <section className={classes.currencySection}>
          <section>
            <TokenSelectInput
              control={control}
              rules={{ required: true }}
              tokens={tokens}
              name="token"
              disabled={!destinationChainConfig || formState.isSubmitting}
              label={`Balance: `}
              className={classes.generalInput}
              sync={(tokenAddress) => {
                setPreflightDetails({
                  ...preflightDetails,
                  token: tokenAddress,
                  receiver: '',
                  tokenAmount: 0,
                  tokenSymbol: '',
                })
              }}
              setValue={setValue}
              options={
                Object.keys(tokens).map((t) => ({
                  value: t,
                  label: (
                    <div className={classes.tokenItem}>
                      {tokens[t]?.imageUri && (
                        <img
                          src={showImageUrl(tokens[t]?.imageUri)}
                          alt={tokens[t]?.symbol}
                        />
                      )}
                      <span>{tokens[t]?.symbol || t}</span>
                    </div>
                  ),
                })) || []
              }
            />
          </section>
          <section>
            <div>
              <TokenInput
                classNames={{
                  input: clsx(classes.tokenInput, classes.generalInput),
                  button: classes.maxButton,
                }}
                tokenSelectorKey={watchToken}
                tokens={tokens}
                disabled={
                  !destinationChainConfig ||
                  formState.isSubmitting ||
                  !preflightDetails.token ||
                  preflightDetails.token === ''
                }
                name="tokenAmount"
                label="Amount to send"
                setValue={setValue}
                control={control}
              />
            </div>
          </section>
        </section>
        <section>
          <AddressInput
            disabled={!destinationChainConfig || formState.isSubmitting}
            name="receiver"
            label="Destination Address"
            placeholder="Please enter the receiving address"
            senderAddress={`${address}`}
            sendToSameAccountHelper={
              destinationChainConfig?.type === homeConfig?.type
            }
            setValue={setValue}
            control={control}
          />
        </section>
        <Fees
          amountFormikName="tokenAmount"
          className={classes.fees}
          fee={bridgeFee}
          feeSymbol={homeConfig?.nativeTokenSymbol}
          symbol={
            preflightDetails && tokens[preflightDetails.token]
              ? tokens[preflightDetails.token].symbol
              : undefined
          }
          amount={watchAmount}
        />
        <section>
          <Button
            disabled={!destinationChainConfig || formState.isSubmitting}
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              backgroundColor: '#262626',
              color: '#ffffff',
              ':hover': {
                backgroundColor: '#262626',
                opacity: 0.9,
              },
            }}
          >
            Start transfer
          </Button>
        </section>
        <section>
          <HelpOutlineIcon
            onClick={() => setAboutOpen(true)}
            className={classes.faqButton}
          />
        </section>
      </form>
      <AboutDrawer open={aboutOpen} close={() => setAboutOpen(false)} />
      <NetworkError open={changeNetworkOpen} />
      {/* <ChangeNetworkDrawer
        open={changeNetworkOpen}
        close={() => setChangeNetworkOpen(false)}
      /> */}
      <PreflightModalTransfer
        open={preflightModalOpen}
        close={() => setPreflightModalOpen(false)}
        receiver={preflightDetails?.receiver || ''}
        sender={address || ''}
        start={() => {
          setPreflightModalOpen(false)
          preflightDetails &&
            deposit(
              preflightDetails.tokenAmount,
              preflightDetails.receiver,
              preflightDetails.token
            )
        }}
        sourceNetwork={homeConfig?.name || ''}
        targetNetwork={destinationChainConfig?.name || ''}
        tokenSymbol={preflightDetails?.tokenSymbol || ''}
        value={preflightDetails?.tokenAmount || 0}
      />
      <TransferActiveModal open={!!transactionStatus} close={resetDeposit} />
      {/* This is here due to requiring router */}
      <NetworkUnsupportedModal />
      <NetworkSelectModal />
    </div>
  )
}
export default TransferPage
